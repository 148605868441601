import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(private http: HttpClient, private storageService:StorageService) {}
  private API_URL = 'https://api.thumshop.de';

  private getAuthHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storageService.getCookie("userSession")}`,
    });
  }
  signup(mail: string) {
    return this.http.post(
      this.API_URL + '/api/v2/shop/newsletter',
      { mail: mail }
    );
  }
  contact(contact: any) {
    return this.http.post<string>(
      this.API_URL + '/api/v2/shop/contact',
      contact
    );
  }

  checkDiscount(discount: string) {
    return this.http.get<any>(
      this.API_URL + '/api/v2/shop/discount/' + discount,
    );
  }
  updateCustomer(Customer: any) {
    return this.http.patch<any>(
      this.API_URL + '/api/v2/users/',
      Customer,
      {
    headers: this.getAuthHeaders(), withCredentials: true,
  }
    );
  }
}
